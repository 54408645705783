<template>
    <div class="client-profile">
        <k-i
                card-title="Заявление на изменение персональных данных"
                :page-title="pageTitle"
                :info-block="infoBlock"
                :cards="cards"
                :hasDraft="hasDraft"
                :show-button="false"
                :actual="getACTUAL_DOCUMENT"
                @download="download"
                @fillOut="$router.push('/cabinet/documents/forms/change-personal-data')"
        />
    </div>
</template>

<script>
    import KI from "../../../components/pages/cabinet/documents/KIType";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "DocumentsPersonalDataChange",
        components: {KI},
        watch: {
            docChanged() {
                if (this.changedDocAlias === "appl-change_pers_data")
                    this.GET_DOCUMENTS("appl-change_pers_data");
            }
        },
        computed: {
            ...mapGetters("DocumentsModule", ["getDOCUMENTS", "getDRAFT", "getACTUAL_DOCUMENT"]),
            ...mapGetters("AutoUpdateModule", ["docChanged", "changedDocAlias"]),
            hasDraft() {
                return !!this.getDRAFT
            },
            cards() {
                return this.getDOCUMENTS ? this.getDOCUMENTS.map(el => {
                    let element = {...el}
                    element.title = "Заявление на изменение персональных данных"
                    element.downloadLink = true
                    return element
                }) : null
            }
        },
        data() {
            return {
                documentKey: 0,
                pageTitle: "Изменение персональных данных",
                infoBlock: null
            }
        },
        methods: {
            ...mapActions("DocumentsModule", [
                "GET_DOCUMENTS",
                "DOWNLOAD_DOCUMENT"
            ]),
            ...mapActions("regModule", [
                "DOWNLOAD_FILE"
            ]),
            download(link) {
                this.DOWNLOAD_DOCUMENT({id: link, name: "Заявление на изменение персональных данных.pdf"})
            },
            downloadScan(file) {
                this.DOWNLOAD_FILE(file)
            },
        },
        mounted() {
            this.GET_DOCUMENTS("appl-change_pers_data")
        }
    }
</script>

<style scoped lang="scss">
</style>